import { config } from "~/data/config";
import { PayUCustomData } from "~/server/types/order";

export type PayuOpenedFrom = "squid" | "webstore";

export type PayuCheckoutMetadata =
  | {
      openedFrom: "squid";
      procurementId: string;
    }
  | {
      openedFrom: "webstore";
      orderId: string;
      brandIdOrKey: string;
    };

export async function openPayUCheckout(
  props: PayUCustomData,
  mode: string,
  metadata: PayuCheckoutMetadata
) {
  console.log(`Opening PayU Checkout from ${metadata.openedFrom}`);

  const enforcePaymethodMethods =
    mode === "UPI" ? "upi|qr" : "creditcard|debitcard";

  let surl!: string;
  let furl!: string;

  if (metadata.openedFrom === "squid") {
    surl = `${window.location.origin}/payu/squid/${metadata.procurementId}`;
    furl = `${window.location.origin}/payu/squid/${metadata.procurementId}`;
  } else if (metadata.openedFrom === "webstore") {
    surl = `${window.location.origin}/payu/webstore/${metadata.brandIdOrKey}/order/${metadata.orderId}`;
    furl = `${window.location.origin}/payu/webstore/${metadata.brandIdOrKey}/order/${metadata.orderId}`;
  }

  const options = {
    key: props.key || "",
    txnid: props.txnId || "",
    amount: props.amount || "",
    productinfo: props.productInfo || "",
    firstname: props.firstName || "",
    email: props.email || "",
    phone: props.phone || "",
    lastname: props.lastName || "",
    surl: surl,
    furl: furl,
    hash: props.hash || "",
    enforce_paymethod: enforcePaymethodMethods,
  };

  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", config.payUUrl);
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", options[key as keyof typeof options]);
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  form.submit();
}
